<template>
  <div>
    <b-col cols="12">
      <app-collapse id="faq-payment-qna" type="margin" class="mt-0 mb-0">
        <!-- Store Pickup Request  For Order -->
        <app-collapse-item
          title="Change Vendor Data For Variant"
          key="cancledOrder"
        >
          <b-form @submit.stop.prevent>
            <b-row>
              <!-- Variant ID -->
              <b-col md="8">
                <validation-observer ref="changeVariantVendorValidate">
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Variant ID" label-for="variant_id">
                        <validation-provider
                          #default="{ errors }"
                          name="variant_id"
                          rules="required"
                        >
                          <b-form-input
                            id="variant_id"
                            type="text"
                            v-model="variantId"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Vendor ID" label-for="vendor_id">
                        <validation-provider
                          #default="{ errors }"
                          name="vendor_id"
                          rules="required"
                        >
                          <b-form-input
                            id="vendor_id"
                            type="text"
                            v-model="vendorId"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">
                            {{ errors[0] }}
                          </small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-col>

              <!-- submit button -->
              <b-col md="6">
                <b-button
                  @click="changeVariantVendorModel"
                  size="lg"
                  variant="primary"
                  type="submit"
                >
                  Submit
                </b-button>
              </b-col>
              <b-modal
                ref="change-variant-vendor-modal-validator"
                cancel-variant="outline-secondary"
                @ok="changeVariantVendorData()"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Validate Action"
              >
                <b-row>
                  <b-col cols="12" class="my-2">
                    please enter the password to validate the request action
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Password" label-for="Password">
                      <b-form-input v-model="password" type="password" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </b-row>
          </b-form>
        </app-collapse-item>
      </app-collapse>
    </b-col>
  </div>
</template>
  <script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { required } from '@validations'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      required,
      variantId: null,
      vendorId: null,
      password: null,
    }
  },
  methods: {
    // check transaction data for processing order
    async changeVariantVendorData() {
      let errors_back = []
      let showDismissibleAlert = false
      await axios
        .post(
          'variants/' + this.variantId + '/vendor/' + this.vendorId + '/change',
          { password: this.password }
        )
        .then((result) => {
          const data = result.data.data.data

          // this.variantId =null
          // this.vendorId =null
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
        })
        .catch((err) => {
          console.log(err)
          showDismissibleAlert = true

          if (err.response.data.data != null) {
            if (
              this.$store.dispatch('Errors/isString', err.response.data.data)
            ) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
          } else {
            errors_back.push({
              error: 'internal server error',
            })
          }
        })
      this.$store.commit('Errors/setErrorsBack', errors_back)
      this.$store.commit(
        'Errors/setShowDisnissaibleAlert',
        showDismissibleAlert
      )
    },
    changeVariantVendorModel() {
      this.$refs.changeVariantVendorValidate.validate().then((success) => {
        if (success) {
          this.$refs['change-variant-vendor-modal-validator'].show()
        } else {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Please Enter The Required Data',
            showConfirmButton: false,
            timer: 1400,
          })
        }
      })
    },
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
  },

  beforeDestroy() {
    this.$store.commit('Errors/setErrorsBack', [])
    this.$store.commit('Errors/setShowDisnissaibleAlert', false)
  },
}
</script>
  