<template>
  <div>
    <b-card>
      <b-col cols="12">
        <ChangeVariantVendorData
          v-if="authPermissions.includes('change-product-vendor')"
        />
        <RestockVariant v-if="authPermissions.includes('restock-variant')" />
        <UpdateVariantStatusToSoldout
          v-if="authPermissions.includes('change-variant-status-soldout')"
        />
      </b-col>
    </b-card>

    <!-- error handler -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDisnissaibleAlert"
      @dismissed="showDisnissaibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import ChangeVariantVendorData from '@/Components/OperationActions/Variant/ChangeVariantVendorData.vue'
import RestockVariant from '@/Components/OperationActions/Variant/RestockVariant.vue'
import UpdateVariantStatusToSoldout from '@/Components/OperationActions/Variant/UpdateVariantStatusToSoldout.vue'

export default {
  components: {
    ChangeVariantVendorData,
    UpdateVariantStatusToSoldout,
    RestockVariant,
  },

  computed: {
    errors_back() {
      return this.$store.state.Errors.errors_back
    },
    showDisnissaibleAlert() {
      return this.$store.state.Errors.showDismissibleAlert
    },
  },

  data() {
    return {
      cancleOrderStatusId: null,
    }
  },
  methods: {
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
  },
}
</script>
    <style lang="scss">
#vs9__listbox {
  z-index: 99999 !important;
}
[dir='rtl'] .vs__selected-options {
  direction: rtl !important;
}
.vs__selected {
  color: black !important;
}
.vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
  min-width: 75px !important;
}
</style>
    